@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .bebas {
    font-family: "Bebas Neue";
  }

  .border-gradient {
    border-width: 2px;
  }

  .border-gradient:hover {
    display: flex;
    align-items: center;
    margin: auto;
    position: relative;
    padding: 30% 2em;
    box-sizing: border-box;
    color: #FFF;
    background: #000;
    background-clip: padding-box;
    border: solid 2px transparent;
  }

  .border-gradient:hover:before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -1;
    margin: 2px;
    border-radius: inherit;
    background: linear-gradient(to top right, #ff80c9, #ff0092, #fb7677);
  }

  .brand-gradient {
    @apply from-gray-900 to-gray-600;
  }

  .bg-brand-gradient {
    @apply bg-gradient-to-br brand-gradient;
  }

  .bg-brand-gradient-hover {
    @apply hover:bg-gradient-to-tr hover:from-purple hover:to-peach;
  }

  .text-brand-gradient {
    @apply bg-gradient-to-tr brand-gradient from-purple to-peach text-purple sm:text-transparent bg-clip-text;
  }

  input.text-brand-gradient::placeholder {
    @apply bg-gradient-to-tr brand-gradient text-transparent bg-clip-text;
  }

  .hover-gradient {
    border-bottom: 2px solid transparent;
  }

  .hover-gradient:hover {
    border-bottom: 2px solid #ff0092;
  }

  .depress {
    @apply scale-100 transform transition-transform ease-in-out duration-200 hover:scale-97;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility:    hidden;
    -ms-backface-visibility:     hidden;
  }

  .ens-gradient {
   @apply from-blue-500 to-purple
  }
}